import { combineReducers } from "redux";
import wallet from "./walletReducers";
import adminReducer from './admin'
import settings from "./settingsReducer";
import poolRedux from './poolReducer'
import feeSettings from './gasFeeSettings'
import networkSwitching from './networkSwitch'
import farmReducer from './farmReducer'
import tokenReducer from "./tokenReducer";
export default combineReducers({
    wallet:wallet,
    admin:adminReducer,
    settings:settings,
    pool:poolRedux,
    gasSettings : feeSettings,
    networkSwitching : networkSwitching,
    farm:farmReducer,
    token:tokenReducer
});