import { getAddress, getTokens } from "../hooks/useTokens"

//pid 3  is the COIN-BUSD pair used for usd values
export const FARMS = [
    {
        pid: 1,
        LpAddress: {
            //bsc
            56: '0xdc83ea74f35a52a5cb13a97491b2ca7f252c2480', //M
            97: '0x1958ea6E4Bae1Db898184568658d6a5Ea35fb697', //T

            //Eth
            1: '', //M
            11155111: '', //T

            //Avax
            43114: '', //M
            43113: '0x06f2195486e3F67F321F2C43f6F6f05a419A5c5F', //T

            //Arbitrum
            42161: '', //M
            421614: '', //T

            //zkSync
            324: '', //M
            280: '', //T
        },
        lpSymbol: "BC-BUSD ONLY LP",
        tokenSymbol: "BC",
        TokenAddress: {
            //bsc
            56: '0x18Be3Ca8AC2883A6e7e28EB85F3cBA3695BD94a3', //M
            97: '0xb4db94DE0DAC28aD7D023dDa5ecB375c951CdBe2', //T

            //Eth
            1: '', //M
            11155111: '', //T

            //Avax
            43114: '', //M
            43113: '0xD37836684547e0aDed54e3a1831F0A7ff6952719', //T

            //Arbitrum
            42161: '', //M
            421614: '', //T

            //zkSync
            324: '', //M
            280: '', //T
        },
        quoteTokenSymbol: "BUSD ONLY",
        QuoteTokenAddress: {
            //bsc
            56: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56', //M
            97: '0x59297CFD2C7426275298d120d09F42098f7b7e6b', //T

            //Eth
            1: '', //M
            11155111: '', //T

            //Avax
            43114: '', //M
            43113: '0x296EFfbF5Cc1Dad8035302E54A6360C09896dc45', //T

            //Arbitrum
            42161: '', //M
            421614: '', //T

            //zkSync
            324: '', //M
            280: '', //T
        },
        isTokenOnly: false,
        RewardToken: {
            //bsc
            56: '0x18Be3Ca8AC2883A6e7e28EB85F3cBA3695BD94a3', //M
            97: '0x59297CFD2C7426275298d120d09F42098f7b7e6b', //T

            //Eth
            1: '', //M
            11155111: '', //T

            //Avax
            43114: '', //M
            43113: '0x296EFfbF5Cc1Dad8035302E54A6360C09896dc45', //T

            //Arbitrum
            42161: '', //M
            421614: '', //T

            //zkSync
            324: '', //M
            280: '', //T
        }
    },
    {
        pid: 2,
        LpAddress: {
            //bsc
            56: '0xc57a601127425bdb9c0f3d120902c85313b8bd16', //M
            97: '0x23c0396B49129132777ea59aBA2Bdf6071f39ED6', //T

            //Eth
            1: '', //M
            11155111: '', //T

            //Avax
            43114: '', //M
            43113: '0xbEeb2BBe8883c6eDB37efCd077a46DA20437fC1C', //T

            //Arbitrum
            42161: '', //M
            421614: '', //T

            //zkSync
            324: '', //M
            280: '', //T
        },
        lpSymbol: "BC-BNB LP",
        tokenSymbol: "BC",
        TokenAddress: {
            //bsc
            56: '0x18Be3Ca8AC2883A6e7e28EB85F3cBA3695BD94a3', //M
            97: '0x59297CFD2C7426275298d120d09F42098f7b7e6b', //T

            //Eth
            1: '', //M
            11155111: '', //T

            //Avax
            43114: '', //M
            43113: '0x296EFfbF5Cc1Dad8035302E54A6360C09896dc45', //T

            //Arbitrum
            42161: '', //M
            421614: '', //T

            //zkSync
            324: '', //M
            280: '', //T
        },
        quoteTokenSymbol: "WBNB",
        QuoteTokenAddress: {
            //bsc
            56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', //M
            97: '0x46cBC64344e55EBB2e6bb741d43dd470d40E1931', //T

            //Eth
            1: '', //M
            11155111: '', //T

            //Avax
            43114: '', //M
            43113: '0xc2DC6f4f0a87F2CA78cB0c5b6B3C0B9A5f7be9c6', //T

            //Arbitrum
            42161: '', //M
            421614: '', //T

            //zkSync
            324: '', //M
            280: '', //T
        },
        isTokenOnly: false,
        RewardToken: {

            //bsc
            56: '0x18Be3Ca8AC2883A6e7e28EB85F3cBA3695BD94a3', //M
            97: '0x59297CFD2C7426275298d120d09F42098f7b7e6b', //T

            //Eth
            1: '', //M
            11155111: '', //T

            //Avax
            43114: '', //M
            43113: '0x296EFfbF5Cc1Dad8035302E54A6360C09896dc45', //T

            //Arbitrum
            42161: '', //M
            421614: '', //T

            //zkSync
            324: '', //M
            280: '', //T
        }
    },
    {
        pid: 3,
        LpAddress: {
            //bsc
            56: '0x2c0be36ba374927afc862d9b45cd5351c526ff45', //M
            97: '0xF935DC8e42f5e5deE834D04dC8c3b907D18a7C79', //T

            //Eth
            1: '', //M
            11155111: '', //T

            //Avax
            43114: '', //M
            43113: '0x8C32aAFee94b4568E029bcfd58390dFf5C70D157', //T

            //Arbitrum
            42161: '', //M
            421614: '', //T

            //zkSync
            324: '', //M
            280: '', //T
        },
        lpSymbol: "BUSD-BNB LP",
        tokenSymbol: "BUSD",
        TokenAddress: {
            //bsc
            56: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56', //M
            97: '0xb4db94DE0DAC28aD7D023dDa5ecB375c951CdBe2', //T

            //Eth
            1: '', //M
            11155111: '', //T

            //Avax
            43114: '', //M
            43113: '0xD37836684547e0aDed54e3a1831F0A7ff6952719', //T

            //Arbitrum
            42161: '', //M
            421614: '', //T

            //zkSync
            324: '', //M
            280: '', //T
        },
        quoteTokenSymbol: "WBNB",
        QuoteTokenAddress: {
            //bsc
            56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', //M
            97: '0x46cBC64344e55EBB2e6bb741d43dd470d40E1931', //T

            //Eth
            1: '', //M
            11155111: '', //T

            //Avax
            43114: '', //M
            43113: '0xc2DC6f4f0a87F2CA78cB0c5b6B3C0B9A5f7be9c6', //T

            //Arbitrum
            42161: '', //M
            421614: '', //T

            //zkSync
            324: '', //M
            280: '', //T
        },
        isTokenOnly: false,
        RewardToken: {
            //bsc
            56: '0x18Be3Ca8AC2883A6e7e28EB85F3cBA3695BD94a3', //M
            97: '0x59297CFD2C7426275298d120d09F42098f7b7e6b', //T

            //Eth
            1: '', //M
            11155111: '', //T

            //Avax
            43114: '', //M
            43113: '0x296EFfbF5Cc1Dad8035302E54A6360C09896dc45', //T

            //Arbitrum
            42161: '', //M
            421614: '', //T

            //zkSync
            324: '', //M
            280: '', //T
        }
    }

]

export const getFarmsChain = () =>{
    try{
        let farms  =  JSON.stringify(FARMS) 
        farms = JSON.parse(farms)
        for(let i=0 ;i<farms.length ;i++){
            farms[i]['lpAddress'] = getAddress(farms[i].LpAddress)
            let tokenAddress = getAddress(farms[i].TokenAddress)
            farms[i]['tokenAddress'] = tokenAddress
            farms[i]['tokenSymbol'] = getTokens().find((val)=>(val.address.toLowerCase() == tokenAddress.toLowerCase())).symbol
            let quoteTokenAddress =getAddress(farms[i].QuoteTokenAddress)
            farms[i]['quoteTokenAddress'] = quoteTokenAddress
            farms[i]['quoteTokenSymbol'] = getTokens().find((val)=>(val.address.toLowerCase() == quoteTokenAddress.toLowerCase())).symbol
            farms[i]['lpSymbol'] = `${farms[i]['tokenSymbol']}-${farms[i]['quoteTokenSymbol']} LP`
            farms[i]['rewardToken'] = getAddress(farms[i].RewardToken)
            if(farms.length-1 == i){
                console.log(farms,'getFarmsChain')
                return farms
            }
        }
    }catch(err){
        console.log('getFarmsChain__err',err)
    }
}

// export const FARMS = [
//     {
//         pid: 1,
//         lpAddress: '0x1F89c000F7a35c4eaba4D1FE43A34Fc349fC4be9',
//         lpSymbol: "BNB-DT LP",
//         tokenSymbol: "BNB",
//         tokenAddress: "0x3742Fc9A0662c86a3f65A975b4D3Ef87Fa13af0c",
//         quoteTokenSymbol: "DT",
//         quoteTokenAddress: "0xa2c5C316365981B1ff903b76AB0b1B7f3A77C7E5",
//         isTokenOnly: false,
//         rewardToken: "0xa2c5C316365981B1ff903b76AB0b1B7f3A77C7E5"
//     },
//     {
//         pid: 2,
//         lpAddress: "0x4be3cde58edECaacCbDb280B12593bB5A9BFc6Db",
//         lpSymbol: "DT-BUSD LP",
//         tokenSymbol: "DT",
//         tokenAddress: "0xa2c5C316365981B1ff903b76AB0b1B7f3A77C7E5",
//         quoteTokenSymbol: "BUSD",
//         quoteTokenAddress: "0x91C7bE4449c76a47EAe1605De7Bcd5a03Cc7d3d2",
//         isTokenOnly: false,
//         rewardToken: "0xa2c5C316365981B1ff903b76AB0b1B7f3A77C7E5"
//     },
//     {
//         pid: 3,
//         lpAddress: '0x280041A60FbCE8eB3032F1c2Ef75654F1160D0D3', 
//         lpSymbol: "BNB-BUSD LP",
//         tokenSymbol: "BNB",
//         tokenAddress: "0x3742Fc9A0662c86a3f65A975b4D3Ef87Fa13af0c",
//         quoteTokenSymbol: "BUSD",
//         quoteTokenAddress: "0x91C7bE4449c76a47EAe1605De7Bcd5a03Cc7d3d2",
//         isTokenOnly: false,
//         rewardToken: "0xa2c5C316365981B1ff903b76AB0b1B7f3A77C7E5"
//     }

// ]


    // {
    //     pid: 1,
    //     lpSymbol: 'DOUB-BNB LP',
    //     lpAddress: '0x56cf2Ea3921A353c6DEa3cBDde1BA5Ef10406DCB',
    //     tokenSymbol: "DOUB",
    //     quoteTokenSymbol: "WBNB",
    //     tokenAddress: '0x2F30560d17044230eF4c6cEb9768D860e2cFf36E',
    //     quoteTokenAddress: "0x3742Fc9A0662c86a3f65A975b4D3Ef87Fa13af0c",
    //     isTokenOnly: false,
    //     rewardToken: "0x2F30560d17044230eF4c6cEb9768D860e2cFf36E"
    // },
    // {
    //     pid: 2,
    //     lpSymbol: 'DOUB-BUSD LP',
    //     lpAddress: '0x8e0793d00c4b08A7F4501D273CDF4BBaDeDF3CF5',
    //     tokenSymbol: "DOUB",
    //     quoteTokenSymbol: "BUSD",
    //     tokenAddress: '0x2F30560d17044230eF4c6cEb9768D860e2cFf36E',
    //     quoteTokenAddress: "0x91C7bE4449c76a47EAe1605De7Bcd5a03Cc7d3d2",
    //     isTokenOnly: false,
    //     rewardToken: "0x2F30560d17044230eF4c6cEb9768D860e2cFf36E"

    // },
    // {
    //     pid: 3,
    //     lpSymbol: 'BNB-BUSD LP',
    //     lpAddress: '0x74a4e192887d8FD0c3761f6ae6CCCfFa0fc65AA1',
    //     tokenSymbol: "WBNB",
    //     quoteTokenSymbol: "BUSD",
    //     tokenAddress: '0x3742Fc9A0662c86a3f65A975b4D3Ef87Fa13af0c',
    //     quoteTokenAddress: "0x91C7bE4449c76a47EAe1605De7Bcd5a03Cc7d3d2",
    //     isTokenOnly: false,
    //     rewardToken: "0x2F30560d17044230eF4c6cEb9768D860e2cFf36E"
    // }