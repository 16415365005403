import bsc from "../assests/images/bsc.png"
import avax from "../assests/images/Avalanche.png"
import arbitrum from "../assests/images/arb.png"
import eth from "../assests/images/eth.png"
import zkSync from "../assests/images/zkSync.png"
// export const RPC_URL = "https://bsc-dataseed1.binance.org/";
// export const CHAIN_ID = 56;

export const MAX_UNIT256 = '115792089237316195423570985008687907853269984665640564039457584007913129639935'

export const ALLOWED_PRICE_IMPACT_LOW= 1 //1%
// Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM= 3 //3%
// Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH = 5  //5%
// Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN= 10 //10%
// Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT  = 15 //15%
// Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%
  
export const RPC_URL = "https://bsc-dataseed.binance.org/";
 export const CHAIN_ID = 56;

//  export const RPC_URL = "https://bsc-testnet.publicnode.com";
//  export const CHAIN_ID = 97;
 
 export const ONEDAYINSECONDS = 86400;
 export const frontendurl  =     window.location.origin;

 export const AdminSaleAddress = "0x518221c5013D745359A2B86863Ef21C75075C623" //"0x5cb3aDAE7fba7439727E62d4FA57d93D3d608084"  //"0x92eAEAbf96AaE481D01e1F5C5aE73C43ecEf645d" //0x22aD5493f105E9EE3fC03e35A87fD45EB65B64a3"

 export const AdminTokenAddress ="0x16ff9EDC133af5fc4ff46bF15562A2F200952A42"   //"0xe43a3EA9a3e411E79Bc732d54D00B8a03DD4E79C" //"0x323655b1169D2f7131C6bd3A20b2F2922AFAf3Eb" //"0x0b754ba95eD079FacCAc71245280dd367e1d4134"

 export const userFee = 5

 export const userFeeWithtoken = 4

 export const MAX_HOPS =  3

 export const CHAINS = [
    {
        NAME: "BNB Chain",
        RPC_URL:"https://bsc-dataseed.binance.org/",
        SYMBOL:"BNB",
        IMAGE:bsc,
           // RPC_URL:[
            //     "https://neat-fragrant-crater.bsc-testnet.quiknode.pro/b5baa8a7cdddef6b65cdb7ac9f8e598244df6e4f/",
            //     "https://bsc-testnet.publicnode.com",
            //     "https://data-seed-prebsc-1-s2.bnbchain.org:8545",
            //     "https://bsc-testnet.blockpi.network/v1/rpc/public"],
        CHAIN_ID:56,
        TRANSACTION : "https://bscscan.com/tx",
        TokenTracker : "https://bscscan.com/token/",
        Testnet : "https://bscscan.com/address",
        // MULTICALL: "0x232e410b1fa58fa37921ad03E5deC6a507cd5CDe",
        EndBlock : "https://bscscan.com/block/countdown"

    },


    // {
    //     NAME: "BNB Test Chain",
    //     RPC_URL:"https://bsc-testnet.publicnode.com",
    //     SYMBOL:"BNB",
    //     IMAGE:bsc,
    //        // RPC_URL:[
    //         //     "https://neat-fragrant-crater.bsc-testnet.quiknode.pro/b5baa8a7cdddef6b65cdb7ac9f8e598244df6e4f/",
    //         //     "https://bsc-testnet.publicnode.com",
    //         //     "https://data-seed-prebsc-1-s2.bnbchain.org:8545",
    //         //     "https://bsc-testnet.blockpi.network/v1/rpc/public"],
    //     CHAIN_ID:97,
    //     TRANSACTION : "https://testnet.bscscan.com/tx",
    //     TokenTracker : "https://testnet.bscscan.com/token/",
    //     Testnet : "https://testnet.bscscan.com/address",
    //     // MULTICALL: "0x232e410b1fa58fa37921ad03E5deC6a507cd5CDe",
    //     EndBlock : "https://testnet.bscscan.com/block/countdown"

    // },


    // {
    //     NAME: "Avalanche",
    //     RPC_URL:"https://api.avax.network/ext/bc/C/rpc",
    //     CHAIN_ID:43114,
    //     SYMBOL:"AVAX",
    //     IMAGE: avax,
    //     TRANSACTION : "https://snowtrace.io/tx/",
    //     TokenTracker : "https://snowtrace.io/token/",
    //     Testnet : "https://snowtrace.io/address/",
    //     EndBlock : "https://snowtrace.io/block/countdown"
    // },
    // {
    //     NAME: "Ethereum",
    //     RPC_URL:"https://rpc2.sepolia.org",
    //     CHAIN_ID:11155111,
    //     IMAGE: eth,
    //     TRANSACTION : "https://sepolia.etherscan.io/tx/",
    //     TokenTracker : "https://sepolia.etherscan.io/token/",
    //     Testnet : "https://sepolia.etherscan.io/address/", 
    //     EndBlock : "https://sepolia.etherscan.io/block/countdown"
        
    // },
    {
        NAME: "Avalanche Test",
        RPC_URL:"https://api.avax-test.network/ext/bc/C/rpc",
        CHAIN_ID:43113,
        SYMBOL:"AVAX",
        IMAGE: avax,
        TRANSACTION : "https://testnet.snowtrace.io/tx/",
        TokenTracker : "https://testnet.snowtrace.io/token/",
        Testnet : "https://testnet.snowtrace.io/address/",
        EndBlock : "https://testnet.snowtrace.io/block/countdown"
    },
    // {
    //     NAME: "Arbitrum",
    //     RPC_URL:"https://arb1.arbitrum.io/rpc",
    //     CHAIN_ID:421614,
    //     IMAGE: arbitrum,
    //     TRANSACTION : "https://arbiscan.io/tx/",
    //     TokenTracker : "https://arbiscan.io/token/", 
    //     Testnet : "https://arbiscan.io/address/",
    //     EndBlock : "https://arbiscan.io/block/countdown"
    // },
    // {
    //     NAME: "zkSync",
    //     RPC_URL:"https://testnet.era.zksync.dev",
    //     CHAIN_ID:280,
    //     IMAGE: zkSync,
    //     TRANSACTION : "https://explorer.zksync.io/tx/",
    //     TokenTracker : "https://explorer.zksync.io/token/",
    //     Testnet : "https://explorer.zksync.io/address/",
    //     EndBlock : "https://explorer.zksync.io/block/countdown"
    // },
  
];

//local
export const FRONT_URL = "200.140.70.139"

//demo
// export const FRONT_URL = "https://donutswapreact.maticz.in"
export const ANALYTICS_URL = "https://donutswap.maticz.in"

//staging
// export const FRONT_URL = "https://stage.donutswap.finance"
// export const ANALYTICS_URL = "https://stageanalytics.donutswap.finance"

//live
// export const FRONT_URL = "https://donutswap.finance"
// export const ANALYTICS_URL = "https://analytics.donutswap.finance"



export const FUND_RECEIVER = "0x12ABeD5514FD6F6716F1154a1Be05beD9E1a11dF"

export const COINS = ["BNB" , "DAI" , "USDT" , "USDC" , "BNB" , "MATIC" , "AVAX" , "CRO" , "FTM"];

export const HC = ["Presale" , "Fairlaunch" , "Auction" , "Subscription"];

export const STATUS = ["Upcoming" , "Live" , "Ended"];

export const TOKEN_FEE = 0.01;

export const ZEROTH_ADDRESS = "0x0000000000000000000000000000000000000000"

export const REFERRER_ADDRESS = "0x754a9c737dF3224B0A200408120c07F74Cef112f"

// 1 day, 7 days, 30 days, 1 year, 5 years
export const DAYS_TO_CALCULATE_AGAINST = [1, 7, 30, 365, 1825]

export const MINIMUM_LIQUIDITY = 0.000000000000001

// export const WBNB_Address = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"; // BSC Mainet

// export const WBNB_Address = "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd";  //BSC Testnet

export const WBNB_Address = "0x1d308089a2d1ced3f1ce36b1fcaf815b07217be3"

export const PROJECT_ID = "b8a1daa2dd22335a2fe1d2e139980ae0"

export const BURN = "0x000000000000000000000000000000000000dEaD";

// export const BLOCKS_PER_YEAR = 10512000;

export const BSC_BLOCK_TIME = 3

export const BLOCKS_PER_YEAR = (60 / BSC_BLOCK_TIME) * 60 * 24 * 365 // 10512000

export const INTERVAL = 300; // 5 minutes




// import bsc from "../images/bsc.png"
// import polygon from "../images/polygon.png"
// import bsctestnet from "../images/bsctestnet.png"
// import eth from "../images/eth.png"
// import ropsten from "../images/ropsten.png"
// // export const RPC_URL = "https://bsc-dataseed1.binance.org/";
// // export const CHAIN_ID = 56;
  
// export const RPC_URL = "wss://bsc-testnet-rpc.publicnode.com";
//  export const CHAIN_ID = 97;
 
//  export const ONEDAYINSECONDS = 86400;

//  export const userFee = 5

//  export const userFeeWithtoken = 4

//  export const CHAINS = [
//     {
//         NAME: "Avalanche-TEST",
//         RPC_URL:"https://api.avax-test.network/ext/bc/C/rpc",
//         CHAIN_ID:43113,
//         IMAGE: bsctestnet,
//         ROUTER: "0x29035dEbC68181B33183E3A7fb9291bC2815644F", //pancake
//         // ROUTER: "0x2a09fba034e26852ff38d5393f3fd15748518791"
//         LIQUIDITY_TOKEN_ADDRESS : "0x36ff68d44e5A38Ac561900431eDd799781DE2c3f",
//         STANDARD_TOKEN_ADDRESS : "0xF037798BDdEd40336498d3121dC5392a2E676778",
//         LOCK_ADDRESS : "0x0D42554AEcff5CB84E16ec6F82f3197B5fF3c2ce", // 0xd915682d1b33e142f0B1F4822502702e11e2F9e8 old
//         BNB : "0x0000000000000000000000000000000000000000",
//         BUSD : "0xd7613f4F96d039f9F15B75D51232719D220B047e",
//         USDT : "0xd7613f4F96d039f9F15B75D51232719D220B047e",
//         USDC : "0xd7613f4F96d039f9F15B75D51232719D220B047e",
//         AIRDROP_ADDRESS : "0x3b3bCCa9BC1C5d5f22F5110613062f48530C60a7", //"0x385b0e7d746ed4711ed3e7bea48e2d9b5052373a",
//         TRANSACTION : "https://testnet.snowtrace.io/tx/",
//         TokenTracker : "https://testnet.snowtrace.io/token/"
//     },
//     {
//         NAME: "Arbitrum",
//         RPC_URL:"https://arb1.arbitrum.io/rpc",
//         CHAIN_ID:42161,
//         IMAGE: bsctestnet,
//         ROUTER: "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506", //pancake
//         // ROUTER: "0x2a09fba034e26852ff38d5393f3fd15748518791"
//         LIQUIDITY_TOKEN_ADDRESS : "0xf997125D111066E1989c94f18bA097C20ca858Ff",
//         STANDARD_TOKEN_ADDRESS : "0xc228c9AB8889901C7C6936C6C6B8d5547006d727",
//         LOCK_ADDRESS : "0x500d3cB1a8709e06C6274CbFa952E4F2a13dd87d", // 0xd915682d1b33e142f0B1F4822502702e11e2F9e8 old
//         // BNB : "0x0000000000000000000000000000000000000000",
//         BNB : "0x0000000000000000000000000000000000000000",
//         DAI  : "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
//         USDT : "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
//         USDC : "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
//         TRANSACTION : "https://arbiscan.io/tx/",
//         TokenTracker : "https://arbiscan.io/token/" 
//     },
//     {
//         NAME: "BSC-Testnet",
//         RPC_URL:"https://sparkling-crimson-pool.bsc-testnet.discover.quiknode.pro/df18f1ec10499d3fcc64dab45f73ed79f2a2d617/",
//         CHAIN_ID:97,
//         IMAGE: bsctestnet,
//         ROUTER: "0x29035dEbC68181B33183E3A7fb9291bC2815644F", //pancake
//         // ROUTER: "0x2a09fba034e26852ff38d5393f3fd15748518791"
//         LIQUIDITY_TOKEN_ADDRESS : "0x527b203C7E0A6463E16DdE46bB71Ce6a9B093595",
//         STANDARD_TOKEN_ADDRESS : "0x06AAA4C8Da86Ad14c735Faa6B65C009f5B5FfE19",
//         LOCK_ADDRESS : "0x388Ba9C5A1213aa8D12C9874A49014434C4aB24C", // 0xd915682d1b33e142f0B1F4822502702e11e2F9e8 old
//         BNB : "0x0000000000000000000000000000000000000000",
//         BUSD : "0xd7613f4F96d039f9F15B75D51232719D220B047e",
//         USDT : "0xd7613f4F96d039f9F15B75D51232719D220B047e",
//         USDC : "0xd7613f4F96d039f9F15B75D51232719D220B047e",
//         AIRDROP_ADDRESS : "0x70b1f342b950e1d6CE3a1C058817Bef2682927cd", //"0x385b0e7d746ed4711ed3e7bea48e2d9b5052373a",
//         TRANSACTION : "https://testnet.snowtrace.io/tx/",
//         TokenTracker : "https://testnet.snowtrace.io/token/"
//     },
// ];

// // export const API_URL = "http://localhost:7097"

// export const API_URL = "http://43.204.99.101:7097"

// // export const API_URL = "https://api.spaceai.finance"

// export const FUND_RECEIVER = "0x12ABeD5514FD6F6716F1154a1Be05beD9E1a11dF"

// export const COINS = ["BNB" , "DAI" , "USDT" , "USDC" , "BNB" , "MATIC" , "AVAX" , "CRO" , "FTM"];

// export const HC = ["Presale" , "Fairlaunch" , "Auction" , "Subscription"];

// export const STATUS = ["Upcoming" , "Live" , "Ended"];

// export const TOKEN_FEE = 0.01;

// export const ZEROTH_ADDRESS = "0x0000000000000000000000000000000000000000"


// // export const WBNB_Address = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"; // BSC Mainet

// // export const WBNB_Address = "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd";  //BSC Testnet

// export const WBNB_Address = "0x1d308089a2d1ced3f1ce36b1fcaf815b07217be3"

// export const PROJECT_ID = "b8a1daa2dd22335a2fe1d2e139980ae0"
