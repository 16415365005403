
export const WALLET_ADDRESS = 'WALLET_ADDRESS';
export const WALLET_DATA = 'WALLET_DATA';
export const WALLET_STATUS = 'WALLET_STATUS';
export const NATIVE_BALANCE   = 'NATIVE_BALANCE'
export const XTOKEN_BALANCE   = 'XTOKEN_BALANCE'

export const SLIPPAGE = 'SLIPPAGE'
export const DEADLINE = 'DEADLINE'
export const MULTI_HOPS = 'MULTI_HOPS'
export const EXPERT_MODE = 'EXPERT_MODE'

export const POOL_DATA = 'POOL_DATA'
export const POOL_USER_DATA = 'POOL_USER_DATA'

export const GAS_PRICE = 'GAS_PRICE'

export const NETWORK_SWITCH = 'NETWORK_SWITCH'

export const FARMS_DATA = 'FARMS_DATA'
export const USER_TOKEN  = 'USER_TOKEN'