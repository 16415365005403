import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import cascading from '../assests/css/expertstyle.module.css';
import { FaRegQuestionCircle } from "react-icons/fa";
import { TiInfo } from "react-icons/ti";
import { useDispatch } from 'react-redux';

import { IoMdClose } from "react-icons/io";
import { CHAINS } from '../config/env';
import { GetChainIndex } from '../hooks/useWeb3';
import { SetuserTokens, getuserTokens } from '../lib/localStorage';
import { USER_TOKEN } from '../constants';
export default function Importtokenmodal(props) {
  const { Token } = props
  const dispatch = useDispatch()
  const [isCheck, SetisCheck] = useState(false)
  const [errors, Seterrors] = useState({})
  const ImportToken = () => {
    try {
      if (isCheck) {
        let userTokens = getuserTokens()
        userTokens.push(Token)
        SetuserTokens(JSON.stringify(userTokens))
        props.onHide()
        dispatch({
          type: USER_TOKEN,
          payload: userTokens
        })
      } else {
        let error = { ...errors }
        error['risk'] = 'Please enable the risk acknowledge'
        Seterrors(error)
      }

    } catch (err) {
      console.log(err, 'ImportToken__err')
    }
  }
  return (
    <div className={`${cascading.networkmodalstyle}`}>
      <Modal centered show={props.show} onHide={props.onHide} className={`${cascading.modalstyle}`} backdrop="static">
        <Modal.Header className={`${cascading.modalclr} ${cascading.modalheader}`}>
          <h4 className={`${cascading.modaltitle}`}>Import Tokens</h4>
          <IoMdClose className={`${cascading.closebtn}`} onClick={props.onHide} />
        </Modal.Header>
        <Modal.Body className={`${cascading.modalclr} ${cascading.modalbody}`}>
          <div className={`${cascading.pinkBox}`}>
            <TiInfo className={`${cascading.pinktriangle}`} />
            <p className='mb-1'>
              Anyone can create tokens on {CHAINS[GetChainIndex()].NAME} with any name, including creating fake versions of existing tokens and tokens that claim to represent projects that do not have a token.
            </p>
          </div>
          <p className={`${cascading.innerheadwhite}`}>If you purchase a fraudulent token, you may be exposed to permanent loss of funds.
          </p>
          <div className={`${cascading.plandiv}`}>
            <div className={`${cascading.badgetheme}`}>
              <FaRegQuestionCircle className={`${cascading.whiteinfo} me-2`} />
              <span>Unknown Source</span>
            </div>
            <div className='d-flex align-items-center justify-content-between mt-3'>
              <div>
                <span className={`${cascading.plantext} me-2`}>{Token.name}</span>
                <span className={`${cascading.planname}`}>{Token.symbol}</span>
                <p className='text-white mt-2 mb-0'>{`${(Token.address).substring(0, 5)}...${Token.address.substring(38, 42)}`}</p>
              </div>
              <div>
                <div className={`${cascading.badgethemefill}`}>
                  Medium Risk
                </div>
              </div>
            </div>
            <button className={`${cascading.declabel} px-0`} 
            onClick={() => {
              window.open(`${CHAINS[GetChainIndex()].Testnet}/${Token.address}`, '_blank');
            }} target="_blank">
              View on BscScan
            </button>
          </div>
          <div className="form-check mt-2 mb-4">
            <input
              className={`form-check-input ${cascading.checkboxstyle}`}
              type="checkbox"
              checked={isCheck}
              id="flexCheckDefault"
              onChange={(e) => {
                Seterrors({})
                SetisCheck(e.target.checked)
              }}
            />
            <label className={`form-check-label mb-0 mt-0 ${cascading.innerheadwhite}`} for="flexCheckDefault">
              I acknowledge the risk
            </label>
          </div>
          <p className="text-danger f-12 d-block text-left">{errors?.risk}</p>
          <button
            className={`btn ${cascading.connectwalletbtn}`}
            type='button'
            onClick={() => { ImportToken() }}
          >
            Proceed
          </button>
          <button className={`btn mt-2 ${cascading.cancelbtn}`} type='button' onClick={() => { props.onHide() }}>Cancel</button>
        </Modal.Body>

      </Modal>
    </div>
  )
}

